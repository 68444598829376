import Icon from "components/Icon";
import React, { useRef, useState } from "react";
import SubCategoryAccordion from "./SubCategoryAccordion";
import EditPromptCategoryModal from "components/EditPromptCategoryModal";

//  accordionitem component
const AccordionItem = ({ question, subCategories, isOpen, onClick, cate_id, userId, prompts, ChatSubmit, favourites, getFavourites, promptType, allPromptsData, refreshPrompts }) => {
    const contentHeight = useRef();
    const [isOpenAddChatCategoryModal, setIsOpenAddChatCategoryModal] = useState(false);

    const justCloseAddChatCategoryModal = () => {
        setIsOpenAddChatCategoryModal(false);
    }

    const handleEditCategory = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setIsOpenAddChatCategoryModal(true)
    }

    const handleRefresh = () => {

    }
    return (
        <>
            <div className={`chatPrompt catWrapper ${isOpen ? "activeWrapper" : ""}`}>
                <button
                    className={`question-container ${isOpen ? "active" : ""}`}
                    onClick={onClick}
                >
                    <p className="question-content parentCategory">{question.name}</p>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {promptType != 'general' ?
                            <p style={{ fontSize: '14px', fontWeight: 'bold' }} onClick={(e) => handleEditCategory(e)}>
                                Edit
                            </p>
                            : ''}
                        {isOpen ?
                            <Icon id="upArrow" className="chat__input-icon" /> :
                            <Icon id="downArrow" className="chat__input-icon" />}
                    </div>
                    {/* <RiArrowDropDownLine className={`arrow ${isOpen ? "active" : ""}`} /> */}
                </button>

                <div
                    ref={contentHeight}
                    className="answer-container subCatAnswer"
                    style={
                        isOpen
                            ? { height: "fit-content" }
                            : { height: "0px" }
                    }
                >
                    <div className="answer-content">
                        <SubCategoryAccordion questionName={question.name}subCategories={subCategories} cat_id={cate_id} prompts={prompts} userId={userId} ChatSubmit={ChatSubmit} favourites={favourites} getFavourites={getFavourites} promptType={promptType} allPromptsData={allPromptsData} refreshPrompts={refreshPrompts} />
                    </div>
                </div>
            </div>

            <EditPromptCategoryModal
                isModalOpen={isOpenAddChatCategoryModal}
                justCloseIt={justCloseAddChatCategoryModal}
                edit_data={question}
                refreshChatCategories={handleRefresh}
            />
        </>
    );
};

const CategoryAccordion = ({ promptsCategories, promptsSubCategories, userId, prompts, ChatSubmit, favourites, getFavourites, promptType, allPromptsData, refreshPrompts }) => {
    const [activeIndex, setActiveIndex] = useState(null);

    const handleItemClick = (index) => {
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return (
        <>
            {promptsCategories?.map((item, index) => (
                <>
                    <AccordionItem
                        key={index}
                        question={item}
                        subCategories={promptsSubCategories}
                        prompts={prompts}
                        cate_id={item._id}
                        isOpen={activeIndex === index}
                        userId={userId}
                        onClick={() => handleItemClick(index)}
                        ChatSubmit={ChatSubmit}
                        favourites={favourites} getFavourites={getFavourites}
                        promptType={promptType}
                        allPromptsData={allPromptsData}
                        refreshPrompts={refreshPrompts}
                    />
                </>
            ))}
        </>
    );
};

export default CategoryAccordion;
