import React, { useState } from "react";
import Icon from "components/Icon";
import axios from "axios";
import { useLocation } from "react-router-dom";
import "./index.css";
import env from "config";
import { useUsersContext } from "context/usersContext";
const BASE_URL = `${env.API_URL}/v1`;

const Modal = ({ isOpen, onClose, children }) => {
  return (
    <>
      {isOpen && (
        <div className="modal-overlay" onClick={onClose}>
          <div
            className="modal-content text-center"
            onClick={(e) => e.stopPropagation()}
          >
            {children}
          </div>
        </div>
      )}
    </>
  );
};

const EditChatCategoryModal = ({
  isModalOpen,
  justCloseIt,
  refreshChatCategories,
  edit_data
}) => {
  const { SSO } = useUsersContext();

  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState();
  const [categoryName, setCategoryName] = useState();

  const handleCategorySubmit = async () => {
    setProcessing(true);
    setError(false);

    if (categoryName === "" || categoryName === undefined) {
      setError(true);
      setProcessing(false);
      return false;
    }

    const response = await axios.put(`${BASE_URL}/chat/category`, {
      _id:edit_data._id,
      name: categoryName,
      userId: SSO?.userId,
      locationId: SSO?.id,
      type: "agency",
    });

    setProcessing(false);
    if (response) {
      justCloseIt();
      refreshChatCategories();
      setCategoryName(null);
    }
  };
  return (
    <div>
      <Modal isOpen={isModalOpen}>
        <div className="scrollable-modal-content">
          <div style={{ position: "relative" }}>
            <p
              style={{
                position: "absolute",
                right: 0,
                width: "12px",
                height: "12px",
                cursor: "pointer",
              }}
              onClick={justCloseIt}
            >
              <Icon id="close" />
            </p>
            <p className="modalText">Add Category</p>
            <div className="formGroup" style={{ marginBottom: "20px" }}>
              <label>Name</label>
              <input
                type="text"
                name="name"
                placeholder="Category Name"
                defaultValue={edit_data.name}
                className="flex-1 modalInputs"
                onChange={(e) => setCategoryName(e.target.value)}
              />
              {error && (
                <small style={{ color: "red" }}>Category name required!</small>
              )}
            </div>
            <button
              className="disagreeBtn"
              style={{
                margin: 0,
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                opacity: processing ? 0.6 : 1,
              }}
              disabled={processing}
              onClick={handleCategorySubmit}
            >
              {processing && <div className="spinner"></div>}
              Save
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default EditChatCategoryModal;
