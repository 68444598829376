import React from 'react';
import './styles.css';

const Toast = ({ message, onClose }) => {
	return (
		<div className="toast" onClick={onClose}>
			{message}
		</div>
	);
};

export default Toast;
