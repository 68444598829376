import React, { useState } from "react";
import Icon from "components/Icon";
import "./index.css";
import localforage from "localforage";
const BASE_URL = `https://apichat.maybetech.com/api/v1`;

const Modal = ({ isOpen, onClose, children }) => {
  return (
    <>
      {isOpen && (
        <div className="modal-overlay" onClick={onClose}>
          <div
            className="modal-content text-center"
            onClick={(e) => e.stopPropagation()}
          >
            {children}
          </div>
        </div>
      )}
    </>
  );
};

// Example usage in another component
const DeleteChatModal = ({ isModalOpen, yesDelete, justCloseIt, contact }) => {
  return (
    <div>
      <Modal isOpen={isModalOpen}>
        <div className="scrollable-modal-content">
          <p className="modalText">
            Are you sure you want to delete {contact?.name != '' ? `'${contact?.name}'` : ''}
          </p>
          <p className="modalDesc">Your prompt will be permanently deleted.</p>
          <div className="modalBtnGroup">
            <button type="submit" className="agreeBtn" onClick={yesDelete}>
              Delete
            </button>
            <p className="disagreeBtn" onClick={justCloseIt}>
              Cancel
            </p>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DeleteChatModal;
