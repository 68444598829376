import React, { useState } from "react";
import "./style.css";
import Data from "data/contacts";
import { useLocation } from "react-router-dom";
import { useUsersContext } from "context/usersContext";
import { useHistory } from "react-router-dom";
import Icon from "components/Icon";

function ChatModal({ processing, enableExplore, enableChat, showExploreContent, setIsSettingsPage }) {
  const history = useHistory();
  const location = useLocation();
  const shouldShowExploreButton = location.pathname.includes("/chat/");
  const { setUserAsUnread, refresh, showToast, SSO } = useUsersContext();
  const [isShowExploreBtn, setIsShowExploreBtn] = useState(false);
  const [isExploreActive, setIsExploreActive] = useState(false);
  const { ChatSubmit } = Data(SSO);


  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      locationId: SSO?.id,
      username: SSO?.first_name,
    };

    const temp = await ChatSubmit(payload);

    if (!temp?.response) {
      refresh(temp);

      const chatUrl = `/chat/${temp.id}`;
      setUserAsUnread(temp.id);
      history.push(chatUrl);
      setIsShowExploreBtn(true);
    } else {
      setIsShowExploreBtn(false);
      showToast("Something went wrong!");
    }
    if (isExploreActive) enableExplore(false);
  };

  const handleSettingsClose = (e) => {
    e.preventDefault();
    setIsSettingsPage(false);
    history.push('/')
  };

  const handleExplorePromots = (e) => {
    e.preventDefault();
    enableExplore();
  };

  return (
    <div className="chat-dropdown" style={{ textAlign: 'right' }}>
      <div>
        <span style={{ fontSize: '20px', fontWeight: 'bold', fontFamily: 'inherit' }}>
          Settings
        </span>
      </div>
      <span style={{ cursor: "pointer", marginLeft: 'auto' }} onClick={(e) => handleSettingsClose(e)}>
        <Icon id="close" />
      </span>
    </div>
  );
}

export default ChatModal;
