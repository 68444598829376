import React, { useState } from "react";
import Icon from "components/Icon";
import "./index.css";
import localforage from "localforage";
import axios from "axios";
import env from "config";

const Modal = ({ isOpen, onClose, children }) => {
  return (
    <>
      {isOpen && (
        <div className="modal-overlay" onClick={onClose}>
          <div
            className="modal-content text-center"
            onClick={(e) => e.stopPropagation()}
          >
            {children}
          </div>
        </div>
      )}
    </>
  );
};
function getRandomColor() {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}
// Example usage in another component
const ShareChatModal = ({
  isModalOpen,
  justCloseIt,
  contact,
  getLocationUsers,
  locationUsers,
  handleRefreshData,
}) => {
  const [proceedToShare, setProceedToShare] = useState(false);
  const [shareToUser, setShareToUser] = useState({});
  const [shareToPermission, setShareToPermission] = useState("use-only");
  const [processing, setProcessing] = useState(false);
  const BASE_URL = `${env.API_URL}/v1`;

  const handleProceedToShare = () => {
    setProceedToShare(true);
    console.log(locationUsers);
  };

  const handleClose = () => {
    justCloseIt();
    setProceedToShare(false);
  };

  const handleShareSubmit = async () => {
    setProcessing(true);
    const payload = {
      chatId: contact.id,
      userId: [
        {
          id: shareToUser?.userId,
          username: shareToUser?.userName,
          type: "shared",
          permission: shareToPermission,
        },
        ...contact?.userId,
      ],
    };
    console.log(payload);
    return await axios
      .put(BASE_URL + "/chat/sharechat", payload)
      .then(async function (response) {
        console.log("response from share chat", response);
        handleClose();
        setProcessing(false);
        handleRefreshData();
      })
      .catch(function (error) {
        console.log(error);
        setProcessing(false);
      });
  };

  return (
    <div>
      <Modal isOpen={isModalOpen}>
        <div className="scrollable-modal-content">
          {proceedToShare ? (
            <div style={{ position: "relative" }}>
              <p
                style={{
                  position: "absolute",
                  right: 0,
                  width: "12px",
                  height: "12px",
                  cursor: "pointer",
                }}
                onClick={handleClose}
              >
                <Icon id="close" />
              </p>
              <p className="modalText">Share  {contact?.name != '' ? `'${contact?.name}'` : ''}</p>
              <div className="formGroup" style={{ marginBottom: "30px" }}>
                <label>Add People</label>
                <div style={{ display: "flex", gap: "18px" }}>
                  <select
                    className="flex-1 modalInputs"
                    style={{ textTransform: "capitalize" }}
                    onChange={(e) => setShareToUser(JSON.parse(e.target.value))}
                  >
                    <option selected disabled>
                      Select User
                    </option>
                    {locationUsers?.map((user) => (
                      <option value={JSON.stringify(user)}>
                        {user?.userName}
                      </option>
                    ))}
                  </select>
                  <select
                    onChange={(e) => setShareToPermission(e.target.value)}
                  >
                    <option value={"use-only"}>Use-only</option>
                    <option value={"editor"}>Editor</option>
                  </select>
                </div>
              </div>
              {contact?.userId?.length > 0 && (
                <div className="formGroup">
                  <label>People with access</label>
                  {contact?.userId?.map((user) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "18px",
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                          style={{
                            width: "30px",
                            height: "30px",
                            flexShrink: 0,
                            borderRadius: "100px",
                            background: getRandomColor(),
                            marginRight: "12px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <p
                            style={{
                              margin: 0,
                              fontFamily: "'Raleway',sans-sarif",
                              fontWeight: 700,
                              fontSize: "14px",
                              textAlign: "center",
                              color: "#fff",
                              lineHeight: "40px",
                              textTransform: "uppercase",
                            }}
                          >
                            {user?.username.split("")[0]}
                          </p>
                        </div>
                        <p style={{ margin: 0 }}>
                          {user?.username}{" "}
                          {user?.type === "author" ? "(You)" : ""}
                        </p>
                      </div>
                      <select style={{ border: "none", outline: "none" }}>
                        <option>Use-only</option>
                        <option>Editor</option>
                      </select>
                    </div>
                  ))}
                </div>
              )}
              <hr style={{ borderColor: "#92A5AF" }} />
              <div className="formGroup" style={{ marginBottom: "30px" }}>
                <label>General access</label>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "18px",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        width: "30px",
                        height: "30px",
                        flexShrink: 0,
                        borderRadius: "100px",
                        background: getRandomColor(),
                        marginRight: "12px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <p
                        style={{
                          margin: 0,
                          fontFamily: "'Raleway',sans-sarif",
                          fontWeight: 700,
                          fontSize: "14px",
                          textAlign: "center",
                          color: "#fff",
                          lineHeight: "40px",
                        }}
                      >
                        E
                      </p>
                    </div>
                    <div>
                      <p style={{ margin: 0 }}>Emerson Osmond (You)</p>
                      <small
                        style={{
                          color: "var(--secondary-tint-1, #92A5AF)",
                          fontFamily: "Raleway, sans-sarif",
                          fontSize: "10px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "normal",
                        }}
                      >
                        Anyone in this group can use this prompt
                      </small>
                    </div>
                  </div>
                  <select style={{ border: "none", outline: "none" }}>
                    <option>Use-only</option>
                    <option>Editor</option>
                  </select>
                </div>
              </div>
              <p
                className="disagreeBtn"
                style={{
                  margin: 0,
                  width: "100%",
                  display: "block",
                  opacity: processing ? 0.6 : 1,
                }}
                disabled={processing}
                onClick={handleShareSubmit}
              >
                {processing && <div className="spinner"></div>}
                Done
              </p>
            </div>
          ) : (
            <>
              <p className="modalText">Confirm access change</p>
              <p className="modalDesc">
                Are you sure you want to change the chat access to shared?
              </p>
              <div className="modalBtnGroup">
                <button
                  type="submit"
                  className="okBtn"
                  onClick={handleProceedToShare}
                >
                  Ok
                </button>
                <p className="disagreeBtn" onClick={handleClose}>
                  Cancel
                </p>
              </div>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default ShareChatModal;
