import React, { useEffect, useState } from "react";
import Icon from "components/Icon";
import axios from "axios";
import "./index.css";
import env from "config";
import { useUsersContext } from "context/usersContext";
const BASE_URL = `${env.API_URL}/v1`;

const Modal = ({ isOpen, onClose, children }) => {
  return (
    <>
      {isOpen && (
        <div className="modal-overlay" onClick={onClose}>
          <div
            className="modal-content text-center"
            onClick={(e) => e.stopPropagation()}
          >
            {children}
          </div>
        </div>
      )}
    </>
  );
};

// Example usage in another component
const AddUserModal = ({
  isModalOpen,
  justCloseIt,
  getLocationUsers
}) => {
  const { SSO } = useUsersContext();
  const [processing, setProcessing] = useState(false);

  // prompt payload states
  const [userName, setUserName] = useState();
  const [userEmail, setUserEmail] = useState();
  const [userRole, setUserRole] = useState();

  // error status
  const [userNameError, setUserNameError] = useState(null);
  const [userEmailError, setUserEmailError] = useState(null);
  const [userRoleError, setUserRoleError] = useState(null);

  const resetAddUserForm = () => {
    setUserName("");
    setUserEmail("");
    setUserRole("");
  };
  useEffect(() => {
  }, []);
  const handleClose = () => {
    justCloseIt();
  };

  const handleAddUserSubmit = async () => {
    setProcessing(true);

    // Reset all previous errors
    setUserNameError(null);
    setUserEmailError(null);
    setUserRoleError(null);

    let hasError = false;

    // Validate the required fields
    if (!userName) {
      setUserNameError("User Name is required");
      hasError = true;
    }

    if (!userEmail) {
      setUserEmailError("Email is required");
      hasError = true;
    }

    if (!userRole) {
      setUserRoleError("Role is required");
      hasError = true;
    }

    if (hasError) {
      setProcessing(false);
      return;
    }

    const response = await axios.post(`${BASE_URL}/user`, {
      username: userName,
      email: userEmail,
      roles: [userRole],
      agency_id: SSO?.agency_id,
      locationId: SSO?.id,
      password: 'password',
      user_id: Math.random(),
    });

    setProcessing(false);
    if (response) {
      handleClose();
      resetAddUserForm();
      getLocationUsers();
    }
  };

  return (
    <div>
      <Modal isOpen={isModalOpen}>
        <div className="scrollable-modal-content">
          <div style={{ position: "relative" }}>
            <p
              style={{
                position: "absolute",
                right: 0,
                width: "12px",
                height: "12px",
                cursor: "pointer",
              }}
              onClick={handleClose}
            >
              <Icon id="close" />
            </p>
            <p className="modalText">Add User</p>
            <div className="formGroup" style={{ marginBottom: "20px" }}>
              <label>Name</label>
              <input
                type="text"
                name="username"
                placeholder="User Name"
                className="flex-1 modalInputs"
                value={userName}
                onChange={(e) => {
                  setUserName(e.target.value);
                  setUserNameError(null);
                }}
              />
              {userNameError && (
                <small style={{ color: "red" }}>{userNameError}</small>
              )}
            </div>
            <div className="formGroup" style={{ marginBottom: "20px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <label>Email</label>
              </div>
              <input
                type="text"
                name="userEmail"
                placeholder="User Name"
                className="flex-1 modalInputs"
                value={userEmail}
                onChange={(e) => {
                  setUserEmail(e.target.value);
                  setUserEmailError(null);
                }}
              />
              {userEmailError && (
                <small style={{ color: "red" }}>{userEmailError}</small>
              )}
            </div>
            <div className="formGroup" style={{ marginBottom: "20px" }}>
              <label>Role</label>
              <input
                type="text"
                name="userRole"
                placeholder="User Role"
                className="flex-1 modalInputs"
                value={userRole}
                onChange={(e) => {
                  setUserRole(e.target.value);
                  setUserRoleError(null);
                }}
              />
              {userRoleError && (
                <small style={{ color: "red" }}>
                  {userRoleError}
                </small>
              )}
            </div>
            <p
              className="disagreeBtn"
              style={{
                margin: 0,
                width: "100%",
                opacity: processing ? 0.6 : 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              disabled={processing}
              onClick={handleAddUserSubmit}
            >
              {processing && <div className="spinner"></div>}
              Save
            </p>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddUserModal;
