import React from 'react'

function index() {
  return (
    <div>
      news feeds
    </div>
  )
}

export default index
