import { usePromptsContaxt } from "context/promptsContext";
import Icon from "components/Icon";
import { useUsersContext } from "context/usersContext";
import { useLocation, useHistory } from "react-router-dom";
import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import env from "config";
import DeleteChatModal from "components/DeleteChatModal";
import SharePromptModal from "components/SharePromptModal";
import EditPromptModal from "components/EditPromptModal";
import EditPromptSubCategoryModal from "components/EditPromptSubCategoryModal";
const BASE_URL = `${env.API_URL}/v1`;

//  accordionitem component
const AccordionItem = ({ questionName,question, prompts, isOpen, onClick, sub_cate_id, userId, ChatSubmit, favourites, getFavourites, promptType, allPromptsData, refreshPrompts }) => {
        const history = useHistory();
    const { addNewMessage, setUserAsUnread, refresh, showToast, enableChat, setOnFromPrompt, SSO, getLocationUsers, locationUsers } = useUsersContext();
    const { handleRefreshData } = usePromptsContaxt();
    const {getPrompts}=usePromptsContaxt();

    const handleSubmit = async (e, user_msg, prompt) => {
        e.preventDefault();
        setOnFromPrompt(userId);
        const payload = {
            locationId: SSO?.id,
            username: SSO?.first_name,
        };
        // enableChat();

        const temp = await ChatSubmit(payload, true);
        if (!temp?.response) {
            refresh(temp);

            const chatUrl = `/chat/${temp.id}`;
            setUserAsUnread(temp.id);
            history.push(chatUrl);
            // const SubmitPrompt = (user_msg, prompt) => {
            addNewMessage(temp.id, user_msg, prompt);
            // };
        } else {
            showToast("Something went wrong!");
        }
    };
    const contentHeight = useRef();

    const [isOpenAddChatCategoryModal, setIsOpenAddChatCategoryModal] = useState(false);
    const [isOpenEditPromptModal, setIsOpenEditPromptModal] = useState(false);
    const [isEditPrompt, setIsEditPrompt] = useState();

    const justCloseAddChatCategoryModal = () => {
        setIsOpenAddChatCategoryModal(false);
    }

    const handleEditCategory = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setIsOpenAddChatCategoryModal(true)
    }

    const handleRefresh = () => {

    }

    const handleEditPrompt = (e, prompt) => {
        e.stopPropagation();
        e.preventDefault();
        setIsEditPrompt(prompt)
        setIsOpenEditPromptModal(true)
    }

    const [askToDeleteModal, setAskToDeleteModal] = useState(false);
    const [askToShareModal, setAskToShareModal] = useState(false);
    const [selectedPrompt, setSelectedPrompt] = useState();
    const [openDropdownIndex, setOpenDropdownIndex] = useState(null);

    // const isDropdownOpen = openDropdownIndex === index;
    const toggleDropdown = (e, index) => {
        e.stopPropagation();
        e.preventDefault();
        setOpenDropdownIndex(openDropdownIndex ? null : index);
    };
    const closedropdownindex = (e, index) => {
        e.stopPropagation();
        e.preventDefault();
        setOpenDropdownIndex(null);
    }

    const askToDelete = async (e, contact) => {
        e.stopPropagation();
        e.preventDefault();
        setSelectedPrompt(contact);
        setAskToDeleteModal(true);
        console.log(contact);
    }
    const askToShare = async (e, contact) => {
        e.stopPropagation();
        e.preventDefault();
        setSelectedPrompt(contact);
        setAskToShareModal(true);
        
    }

    const handleFavourite = async (e, prompt) => {
        e.stopPropagation();
        e.preventDefault();
        const response = await axios.get(`${BASE_URL}/chat/add/favourite?prompt_id=${prompt?._id}&user_id=${SSO?.userId}&location_id=${SSO?.id}`);
        // await axios.get(`${BASE_URL}/remove/favourite?id=${prompt?.id}`);
        if (response) {
            console.log(prompt, response)
            getFavourites();
            handleRefreshData();
        }
    }

    const justCloseIt = () => {
        setAskToDeleteModal(false);
        setOpenDropdownIndex(false);
        setAskToShareModal(false);
        setIsOpenEditPromptModal(false);
    }

    const handleDeleteClick = async () => {
       
        
        const response = await axios.get(`${BASE_URL}/chat/promptDelete?_id=${selectedPrompt._id}`);
        if (response) {
            // setUsers((users) => users.filter((user) => user.id !== selectedContact?.id));
            const redirectUrl = `/`;
            history.push(redirectUrl);
            justCloseIt();
            getPrompts();
            handleRefreshData();
            } else {
            showToast('Something went wrong!')
        }
    };
    const moveToCategoryHandler = async (e, categoryId,promptId) => {
        const payload = {
          promptId: promptId,
          category: categoryId,
        };
        return await axios
          .put(BASE_URL + "/chat/shareprompt", payload)
          .then(async function (response) {
            console.log(response);
            handleRefreshData();
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    return (
        <>
            <div className="wrapper subCategoryWrapper">
                <button
                    className={`question-container ${isOpen ? "active" : ""}`}
                    onClick={onClick}
                >
                    <p className="question-content">{question.name}</p>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {promptType != 'general' ?
                            <p style={{ fontSize: '14px', fontWeight: 'bold' }} onClick={(e) => handleEditCategory(e)}>
                                Edit
                            </p>
                            : ''}
                        {isOpen ?
                            <Icon id="upArrow" className="chat__input-icon" /> :
                            <Icon id="downArrow" className="chat__input-icon" />}
                    </div>
                    {/* <RiArrowDropDownLine className={`arrow ${isOpen ? "active" : ""}`} /> */}
                </button>

                <div
                    ref={contentHeight}
                    className="answer-container"
                    style={
                        isOpen
                            ? { height: "fit-content" }
                            : { height: "0px" }
                    }
                >
                    <div className="answer-content">
                        {prompts?.map((item, index) => (
                            <>
                                {item.sub_category == sub_cate_id ?
                                    <div className="prompt_box_sidebar"
                                        onClick={(event) => handleSubmit(event, item.title, item.prompt_value)} >
                                        <div className="prompt_text">
                                            {item.title}
                                        </div>

                                        <div className="sidebar-contact__bottom-content" onMouseLeave={(e) => closedropdownindex(e, index)} >
                                            <div className="sidebar-contact__time">
                                                <p style={{ margin: '0 8px 0 0', fontSize: '12px', fontWeight: 'bold' }} onClick={(e) => handleEditPrompt(e, item)}>Edit</p>
                                                <Dropdown
                                                    showDropdown={openDropdownIndex === index}
                                                    toggleDropdown={(e) => toggleDropdown(e, index)}
                                                    onDeleteClick={(e) => askToDelete(e, item)}
                                                    onShareClick={(e) => askToShare(e, item)}
                                                    promptType={promptType}
                                                    categories={allPromptsData.prompt_category}
                                                    promptId={item._id}
                                                    moveToCategoryHandler={moveToCategoryHandler}
                                                    questionName={questionName}
                                                // (e) => handleDeleteClick(e, contact.id)
                                                />
                                                <span
                                                    style={{ padding: 0 }}
                                                    className="deleteChatBtn"
                                                    onClick={(e) => handleFavourite(e, item)}
                                                >
                                                    <Icon id={favourites?.some(favourite => favourite.prompt_id === item._id) ? 'filledStar' : 'star'} />
                                                </span>
                                            </div>
                                        </div>
                                        {/* <Icon id="send" className="chat__input-icon prompt_icon" /> */}
                                    </div>
                                    :
                                    ''}
                            </>
                        ))}
                    </div>
                </div>
            </div>

            <DeleteChatModal
                isModalOpen={askToDeleteModal}
                contact={selectedPrompt}
                yesDelete={handleDeleteClick}
                justCloseIt={justCloseIt}
            />
            <SharePromptModal
                isModalOpen={askToShareModal}
                contact={selectedPrompt}
                justCloseIt={justCloseIt}
                getLocationUsers={getLocationUsers}
                locationUsers={locationUsers}
            />

            <EditPromptSubCategoryModal
                isModalOpen={isOpenAddChatCategoryModal}
                justCloseIt={justCloseAddChatCategoryModal}
                edit_data={question}
                refreshChatCategories={handleRefresh}
                categories={allPromptsData?.prompt_category?.filter(obj => obj.type != 'super-admin')}
            />

            <EditPromptModal
                isModalOpen={isOpenEditPromptModal}
                categories={allPromptsData?.prompt_category?.filter(obj => obj.type != 'super-admin')}
                subCategories={allPromptsData?.prompt_sub_category?.filter(obj => obj.type != 'super-admin')}
                justCloseIt={justCloseIt}
                getUpdatePromptsData={refreshPrompts}
            />
        </>
    );
};

const SubCategoryAccordion = ({ questionName,subCategories, cat_id, userId, prompts, ChatSubmit, favourites, getFavourites, promptType, allPromptsData, refreshPrompts }) => {
    const [activeIndex, setActiveIndex] = useState(null);

    const handleItemClick = (index) => {
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return (
        <>
            {subCategories?.map((item, index) => (
                <>
                    {item.category_id == cat_id ?
                        <AccordionItem
                            key={index}
                            question={item}
                            prompts={prompts}
                            sub_cate_id={item._id}
                            isOpen={activeIndex === index}
                            userId={userId}
                            onClick={() => handleItemClick(index)}
                            ChatSubmit={ChatSubmit}
                            favourites={favourites}
                            getFavourites={getFavourites}
                            promptType={promptType}
                            allPromptsData={allPromptsData}
                            refreshPrompts={refreshPrompts}
                            questionName={questionName}
                        />
                        :
                        <></>
                    }
                </>
            ))}
        </>
    );
};


const Dropdown = ({questionName, promptId,categories,showDropdown, toggleDropdown, onDeleteClick, onShareClick, promptType,moveToCategoryHandler }) => {
    const [showSubmenu, setShowSubmenu] = useState(false);
    const dropdownRef = useRef(null);
    const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });

    const toggleSubmenu = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setShowSubmenu(!showSubmenu);
    };

    const handleMenu = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const { clientX, clientY } = e;
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;
        const dropdownWidth = 200; // Approximate width of the dropdown menu
        const dropdownHeight = 150; // Approximate height of the dropdown menu

        let adjustedLeft = clientX;
        let adjustedTop = clientY;

        // Adjust horizontal position if the dropdown would overflow the viewport
        if (clientX + dropdownWidth > viewportWidth) {
            adjustedLeft = viewportWidth - dropdownWidth - 10; // 10px margin from the edge
        }

        // Adjust vertical position if the dropdown would overflow the viewport
        if (clientY + dropdownHeight > viewportHeight) {
            adjustedTop = viewportHeight - dropdownHeight - 10; // 10px margin from the edge
        }

        setDropdownPosition({ top: adjustedTop, left: adjustedLeft });
        toggleDropdown(e);
        setShowSubmenu(false);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            toggleDropdown(event);
        }
    };

    useEffect(() => {
        if (showDropdown) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showDropdown]);
// console.log(categories,"ssssss")
    return (
        <span style={{ height: "100px", overflowY: "scroll" }} className="userChatIcon truncateUserName" Style={{ padding: 0 }} onClick={(e) => handleMenu(e)}>
            <Icon id="cogSm" />
            {showDropdown && (
                <div ref={dropdownRef} className="settingMenu"
                    style={{
                        position: "fixed",
                        top: dropdownPosition.top,
                        left: dropdownPosition.left,
                        zIndex: 1000,
                        backgroundColor: "white",
                        boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                        borderRadius: "4px",
                    }}>
                    {promptType !== 'general' ? (
                        <div onClick={onDeleteClick}>Delete</div>
                    ) : ''}
                    <div onClick={onShareClick}>Share</div>
                    {promptType !== 'general' ? (
                        <>
                            <div onClick={toggleSubmenu}>{`Move >`}</div>
                            {showSubmenu && (
                                <div className="submenu">
                                   { categories?.map((item) => (
                                     item.type === 'agency' && item.name!==questionName  ? (
                              <div onClick={(e)=>moveToCategoryHandler(e,item._id,promptId)}>{item.name}</div>
                            ) : ''
                          ))}
                                </div>
                            )}
                        </>
                    ) : ''}
                </div>
            )}
        </span>
    );
};

export default SubCategoryAccordion;
