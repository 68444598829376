import React, { useState } from "react";
import Icon from "components/Icon";
import { Link } from "react-router-dom";
import { useUsersContext } from "context/usersContext";
import { useHistory } from "react-router-dom";
import DeleteChatModal from "components/DeleteChatModal";
import ShareChatModal from "components/ShareChatModal";
import axios from "axios";
import Data from "data/contacts";
import env from "config";
const BASE_URL = `${env.API_URL}/v1`;

const SharedPrompt = ({ contact, onDelete, index, openDropdownIndex, setOpenDropdownIndex, favourites, getFavourites }) => {
  const { addNewMessage, setUserAsUnread, refresh, showToast, enableChat, setOnFromPrompt, setUsers, SSO } = useUsersContext();
  const history = useHistory();
  const { ChatSubmit } = Data(SSO);
  // alert(SSO?.id)

  const [askToDeleteModal, setAskToDeleteModal] = useState(false);
  const [askToShareModal, setAskToShareModal] = useState(false);
  const [selectedContact, setSelectedContact] = useState();

  const isOpen = openDropdownIndex === index;
  const toggleDropdown = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setOpenDropdownIndex(isOpen ? null : index);
  };

  const askToDelete = async (e, contact) => {
    e.stopPropagation();
    e.preventDefault();
    setSelectedContact(contact);
    setAskToDeleteModal(true);
  }
  const askToShare = async (e, contact) => {
    e.stopPropagation();
    e.preventDefault();
    setSelectedContact(contact);
    setAskToShareModal(true);
  }

  const handleDeleteClick = async () => {
    const deleteRes = await onDelete(selectedContact?.id);
    if (deleteRes.status === 200) {
      setUsers((users) => users.filter((user) => user.id !== selectedContact?.id));
      const redirectUrl = `/`;
      history.push(redirectUrl);
      justCloseIt();
    } else {
      showToast('Something went wrong!')
    }
  };

  const handleFavourite = async (e, contact) => {
    e.stopPropagation();
    e.preventDefault();
    const response = await axios.get(`${BASE_URL}/chat/add/favourite?chat_id=${contact?.id}&user_id=${SSO?.userId}&location_id=${SSO?.id}`);
    // await axios.get(`${BASE_URL}/remove/favourite?id=${contact?.id}`);
    if (response) {
      getFavourites();
    }
    console.log(contact, response)
  }

  const justCloseIt = () => {
    setAskToDeleteModal(false);
    setOpenDropdownIndex(false);
    setAskToShareModal(false);
  }

  const handleSubmit = async (e, user_msg, prompt) => {
    e.preventDefault();
    setOnFromPrompt(SSO?.userId);
    const payload = {
      locationId: SSO?.id,
      username: SSO?.first_name,
    };
    enableChat();

    const temp = await ChatSubmit(payload, true);

    if (!temp?.response) {
      refresh(temp);

      const chatUrl = `/chat/${temp.id}`;
      setUserAsUnread(temp.id);
      history.push(chatUrl);

      // const SubmitPrompt = (user_msg, prompt) => {
      addNewMessage(temp.id, user_msg, prompt);
      // };
    } else {
      showToast("Something went wrong!");
    }
  };
  const isFavourite = favourites?.some(favourite => favourite.chat_id === contact.id);
  return (
    <>
      <div className="prompt_box_sidebar"
        onClick={(event) => handleSubmit(event, contact.title, contact.prompt_value)} >
        <div className="prompt_text">
          {contact.title}
        </div>
        <div className="sidebar-contact__bottom-content">
          <div className="sidebar-contact__time">
            <Dropdown
              showDropdown={openDropdownIndex === index}
              toggleDropdown={(e) => toggleDropdown(e, index)}
              onDeleteClick={(e) => askToDelete(e, contact)}
              onShareClick={(e) => askToShare(e, contact)}
            // (e) => handleDeleteClick(e, contact.id)
            />
            <span
              style={{ padding: 0 }}
              className="deleteChatBtn"
              onClick={(e) => handleFavourite(e, contact)}
            >
              <Icon id={isFavourite ? 'filledStar' : 'star'} />
            </span>
          </div>
        </div>
        {/* <Icon id="send" className="chat__input-icon prompt_icon" /> */}
      </div>

      <DeleteChatModal
        isModalOpen={askToDeleteModal}
        contact={selectedContact}
        yesDelete={handleDeleteClick}
        justCloseIt={justCloseIt}
      />
      <ShareChatModal
        isModalOpen={askToShareModal}
        contact={selectedContact}
        justCloseIt={justCloseIt}
      />
    </>
  );
};



const Dropdown = ({ showDropdown, toggleDropdown, onDeleteClick, onShareClick }) => {
  const [showSubmenu, setShowSubmenu] = useState(false);

  const toggleSubmenu = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setShowSubmenu(!showSubmenu);
  };

  const handleMenu = (e) => {
    toggleDropdown(e);
    setShowSubmenu(false);
  }
  return (
    <span className="userChatIcon truncateUserName" onClick={(e) => handleMenu(e)}>
      <Icon id="cogSm" />
      {showDropdown && (
        <div className="settingMenu">
          <div onClick={onDeleteClick}>Delete</div>
          <div onClick={onShareClick}>Share</div>
          <div onClick={toggleSubmenu}>{`Move >`}</div>
          {showSubmenu && (
            <div className="submenu">
              {/* Add your submenu items here */}
              <div>Submenu Item 1</div>
              <div>Submenu Item 2</div>
            </div>
          )}
        </div>
      )}
    </span>
  );
};

export default SharedPrompt;
