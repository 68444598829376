import { Environment } from "../enums";

const env = {
  // API_URL: "http://localhost:8082/api",
  API_URL: "https://stagingapi.maybetech.com/api",
  Chat_API: "https://pandora.maybetech.com/api",
  Chat_Auth: "a71860da-1a58-4797-90f1-02dcb5fd06b1",
  API_ENV: "/chat",
};

// if (process.env.REACT_APP_ENV === Environment.DEVELOPMENT) {
//   env.API_URL = "https://betachatapi.maybetech.com/api";
//   env.Chat_API = "https://dev-pandora.maybetech.com/api";
//   env.Chat_Auth = "19e20c56-3355-422d-96d0-97706036ddfd";
//   env.API_ENV = "/chat/dev-chat";
// }

// if (process.env.REACT_APP_ENV === Environment.PRODUCTION) {
//   env.API_URL = "https://apichat.maybetech.com/api";
//   env.Chat_API = "https://pandora.maybetech.com/api";
//   env.Chat_Auth = "a71860da-1a58-4797-90f1-02dcb5fd06b1";
//   env.API_ENV = "/chat";
// }

export default env;
