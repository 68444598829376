import React, { useState } from "react";
import "./style.css";
import Data from "data/contacts";
import { useLocation } from "react-router-dom";
import { useUsersContext } from "context/usersContext";
import { useHistory } from "react-router-dom";
import Icon from "components/Icon";

function ChatModal({ processing, enableExplore, enableChat, showExploreContent, setIsSettingsPage }) {
  const history = useHistory();
  const location = useLocation();
  const shouldShowExploreButton = location.pathname.includes("/chat/");
  const { setUserAsUnread, refresh, showToast, SSO } = useUsersContext();
  const [isShowExploreBtn, setIsShowExploreBtn] = useState(false);
  const [isExploreActive, setIsExploreActive] = useState(false);
  const { ChatSubmit } = Data(SSO);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      locationId: SSO?.id,
      username: SSO?.first_name,
    };

    const temp = await ChatSubmit(payload);

    if (!temp?.response) {
      refresh(temp);

      const chatUrl = `/chat/${temp.id}`;
      setUserAsUnread(temp.id);
      history.push(chatUrl);
      setIsShowExploreBtn(true);
    } else {
      setIsShowExploreBtn(false);
      showToast("Something went wrong!");
    }
    if (isExploreActive) enableExplore(false);
  };

  const handleConnect = (e) => {
    e.preventDefault();
    window.location.href = `https://connect.maybetech.com/?profile_id=${SSO?.id}&profile_name=${SSO?.email}&username=${SSO?.first_name}&type=chat`;
  };

  const handleSettings = (e) => {
    e.preventDefault();
    history.push('/settings');
    setIsSettingsPage(true);
  };

  const handleExplorePromots = (e) => {
    e.preventDefault();
    enableExplore();
  };

  return (
    <div className="chat-dropdown">
      <div>
        <button
          className={`btn btn-pencil`}
          disabled={processing}
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "100px",
            padding: 0,
          }}
          aria-label="New chat"
          onClick={handleSubmit}
        >
          <Icon id="pencil" />
        </button>
        <button
          className={`btn btn-newChat ${!showExploreContent ? "activeBtn" : ""
            }`}
          disabled={processing}
          aria-label="New chat"
          style={{ width: "115px" }}
          onClick={enableChat}
        >
          Chats
        </button>

        <button
          className={`btn btn-explore ${showExploreContent ? "activeBtn" : ""}`}
          aria-label="New chat"
          style={{ width: "115px" }}
          onClick={enableExplore}
        >
          <Icon id="exp_dots" className="exp-dots" />
          Prompts
        </button>
      </div>
      <span style={{ cursor: "pointer" }} onClick={(e) => handleConnect(e)}>
        <Icon id="cog" />
      </span>
    </div>
  );
}

export default ChatModal;
