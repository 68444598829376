import React, { useContext, useState } from "react";
import "./styles/main.css";
import { useUsersContext } from "context/usersContext";
import ChatModal from "./components/ChatModal";
import { useLocation } from "react-router-dom";
import Prompts from "pages/Chat/components/Prompts";
import ChatAccordions from "pages/Chat/components/ChatAccordions";
import AddPromptModal from "components/AddPromptModal";
import { PromptsContext } from "context/promptsContext";
import PromptAccordions from "pages/Chat/components/PromptAccordions";
import Data from "data/contacts";
import FavChatAccordions from "pages/Chat/components/FavChatAccordions";
import SharedChatAccordions from "pages/Chat/components/SharedChatAccordions";
import FavPromptAccordions from "pages/Chat/components/FavPromptAccordions";
import AddChatCategoryModal from "components/AddChatCategoryModal";
import SharedPromptAccordions from "pages/Chat/components/SharedPromptAccordions";

const Sidebar = ({setIsSettingsPage}) => {
  const { users: contacts, locValid, isActiveSide, handleDeleteContact, processing, showExploreContent, enableExplore, enableChat, SSO, agencyConfig } = useUsersContext();
  const { promptsData, getPrompts, favouritesData, getFavourites, chatCategories, getChatCategories } = useContext(PromptsContext);
  const [isOpenAddPromptModal, setIsAddPromptModal] = useState(false);
  const [isOpenAddChatCategoryModal, setIsOpenAddChatCategoryModal] = useState(false);
  // const { ChatSubmit } = Data();

  const addPromptHandle = () => {
    setIsAddPromptModal(true);
  }

  const justCloseIt = () => {
    setIsAddPromptModal(false);
  }

  const addChatCategoryHandle = () => {
    setIsOpenAddChatCategoryModal(true);
  }

  const justCloseAddChatCategoryModal = () => {
    setIsOpenAddChatCategoryModal(false);
  }

  const { pathname } = useLocation();
  const userId = pathname.split("/")[2];
  const favChatIds = favouritesData?.map(fav => fav.chat_id);
  const favPromptIds = favouritesData?.map(fav => fav.prompt_id);

  const favChats = contacts?.filter(chat => favChatIds?.includes(chat.id));
  const favPrompts = promptsData?.prompt?.filter(prompt => favPromptIds?.includes(prompt._id));
  // const chatCategoriesStatic = [{ name: 'Uncategorised' }, { name: 'Run or better ads' }, { name: 'FAQs for Social Media' }]
  const favourites = [{ name: 'Favourites' }]
  const Shared = [{ name: 'Shared with you' }]
  console.log(favChats,"Sss'd'd")
  return ( 
    <>
      {locValid ? (
        <aside className={`sidebar ${!isActiveSide ? 'dNone' : ''}`}>
          <header className="header">
            <ChatModal processing={processing} enableExplore={enableExplore} enableChat={enableChat} showExploreContent={showExploreContent} setIsSettingsPage={setIsSettingsPage} />
          </header>
          {showExploreContent ? (
            <div className="sidebar__explore-content">
             
              <div className="sidebarWrapper">
                {/* <Prompts userId={userId} /> */}
                {/* <PromptAccordions categories={favourites} contacts={favPrompts} onDelete={handleDeleteContact} /> */}
                <FavPromptAccordions promptsData={promptsData} categories={favourites} contacts={favPrompts} onDelete={handleDeleteContact} favourites={favouritesData} getFavourites={getFavourites} />
                <SharedPromptAccordions categories={Shared} contacts={promptsData?.prompt?.filter(item => {
                  return item.userId.some(user => user.id === SSO.userId && user.type === "shared");
                })} onDelete={handleDeleteContact} favourites={favouritesData} getFavourites={getFavourites} />
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '16px', marginTop: '40px' }}>
                  <h2 className="promptsHeading" style={{ margin: 0 }}>General Prompts</h2>
                </div>
                {/* <ChatAccordions categories={categories} contacts={contacts} onDelete={handleDeleteContact} /> */}
                {/* <Prompts userId={userId} promptsData={promptsData} type="general"/> */}
                <Prompts userId={userId} promptsData={promptsData} type="general" favourites={favouritesData} getFavourites={getFavourites} agencyConfig={agencyConfig} />
               
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '16px', marginTop: '10px' }}>
                  <h2 className="promptsHeading" style={{ margin: 0 }}>Your Prompts</h2>
                  <p style={{ margin: '0 6px 0 0', cursor: 'pointer', textDecoration: 'underline' }} onClick={addPromptHandle}>+ Add Prompt</p>
                </div>
                <Prompts  userId={userId} promptsData={promptsData} type="personal" favourites={favouritesData} getFavourites={getFavourites} refreshPrompts={getPrompts} />
              </div>
            </div>
            // <div className="sidebar__explore-content">
            // </div>
          ) : (
            <div className="sidebar__contacts">
             
              {processing ? (
                <div className="chatSpinner"></div>
              ) : (
                <div className="sidebarWrapper">
                  <FavChatAccordions addChatCategoryHandle={addChatCategoryHandle}  chatCategories={chatCategories} categories={favourites} contacts={favChats} onDelete={handleDeleteContact} favourites={favouritesData} getFavourites={getFavourites} />

                  <SharedChatAccordions categories={Shared} contacts={contacts.filter(item => {
                    return item.userId.some(user => user.id === SSO.userId && user.type === "shared");
                  })} onDelete={handleDeleteContact} favourites={favouritesData} getFavourites={getFavourites} />

                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '16px', marginTop: '10px' }}>
                    <h2 className="promptsHeading" style={{ margin: 0 }}>Your Chats</h2>
                    <p style={{ margin: '0 6px 0 0', cursor: 'pointer', textDecoration: 'underline' }} onClick={addChatCategoryHandle}>+ Add Category</p>
                  </div>

                  <ChatAccordions addChatCategoryHandle={addChatCategoryHandle} categories={chatCategories} contacts={contacts.filter(item => {
                    return item.userId.some(user => user.id === SSO.userId && user.type === "author");
                  })} onDelete={handleDeleteContact} favourites={favouritesData} getFavourites={getFavourites} 
                  handleRefresh={getChatCategories} />

                  {/* <ChatAccordions category={'Run Better Ads'} contacts={contacts} onDelete={handleDeleteContact} />
                  <ChatAccordions category={'Frequently Asked Social Media Questions'} contacts={contacts} onDelete={handleDeleteContact} /> */}
                  {/* {contacts.map((contact, index) => (
                    <Contact key={index} contact={contact} onDelete={handleDeleteContact} />
                  ))} */}
                </div>
              )}
            </div>
            // <div className="sidebar__contacts">
            //   {processing ? (
            //     <div className="chatSpinner"></div>
            //   ) : (
            //     contacts.map((contact, index) => (
            //       <Contact key={index} contact={contact} onDelete={handleDeleteContact} />
            //     ))
            //   )}
            // </div>
          )}
          <AddPromptModal
            isModalOpen={isOpenAddPromptModal}
            categories={promptsData?.prompt_category?.filter(obj => obj.type != 'super-admin')}
            subCategories={promptsData?.prompt_sub_category?.filter(obj => obj.type != 'super-admin')}
            justCloseIt={justCloseIt}
            getUpdatePromptsData={getPrompts}
          />
          <AddChatCategoryModal
            isModalOpen={isOpenAddChatCategoryModal}
            justCloseIt={justCloseAddChatCategoryModal}
            refreshChatCategories={getChatCategories}
          />
        </aside>
      ) : (
        <></>
      )}
    </>
  );
};

export default Sidebar;
