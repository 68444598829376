import React, { useEffect, useRef, useState } from "react";
import Icon from "components/Icon";
import { Link } from "react-router-dom";
import { useUsersContext } from "context/usersContext";
import { useHistory } from "react-router-dom";
import DeleteChatModal from "components/DeleteChatModal";
import ShareChatModal from "components/ShareChatModal";
import axios from "axios";
import Data from "data/contacts";
import env from "config";
import { usePromptsContaxt } from "context/promptsContext";
const BASE_URL = `${env.API_URL}/v1`;

const FavPrompt = ({ promptsData,contact, onDelete, index, openDropdownIndex, setOpenDropdownIndex, favourites, getFavourites }) => {
  const { addNewMessage, setUserAsUnread, refresh, showToast, enableChat, setOnFromPrompt, setUsers, SSO,handleRefreshData } = useUsersContext();
  const history = useHistory();
  const { ChatSubmit } = Data(SSO);
  // alert(SSO?.id)

  const [askToDeleteModal, setAskToDeleteModal] = useState(false);
  const [askToShareModal, setAskToShareModal] = useState(false);
  const [selectedContact, setSelectedContact] = useState();

  const isOpen = openDropdownIndex === index;
  const toggleDropdown = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setOpenDropdownIndex(isOpen ? null : index);
  };

  const askToDelete = async (e, contact) => {
    e.stopPropagation();
    e.preventDefault();
    setSelectedContact(contact);
    setAskToDeleteModal(true);
  }
  const askToShare = async (e, contact) => {
    e.stopPropagation();
    e.preventDefault();
    setSelectedContact(contact);
    setAskToShareModal(true);
  }

  const handleDeleteClick = async () => {
    const deleteRes = await onDelete(selectedContact?._id);
    if (deleteRes.status === 200) {
      setUsers((users) => users.filter((user) => user.id !== selectedContact?._id));
      const redirectUrl = `/`;
      history.push(redirectUrl);
      justCloseIt();
    } else {
      showToast('Something went wrong!')
    }
  };

  const removeFromFav = async (e, contact) => {
    e.stopPropagation();
    e.preventDefault();
    console.log(contact);
    const favoriteId = favourites.find(favorite => favorite.prompt_id === contact?._id)?._id;
    const response = await axios.get(`${BASE_URL}/chat/remove/favourite?chatId=${favoriteId}&user_id=${SSO?.userId}&location_id=${SSO?.id}`);
    if (response) {
      getFavourites();
    }
  }

  const justCloseIt = () => {
    setAskToDeleteModal(false);
    setOpenDropdownIndex(false);
    setAskToShareModal(false);
  }

  const handleSubmit = async (e, user_msg, prompt) => {
    e.preventDefault();
    setOnFromPrompt(SSO?.userId);
    const payload = {
      locationId: SSO?.id,
      username: SSO?.first_name,
    };
    enableChat();

    const temp = await ChatSubmit(payload, true);

    if (!temp?.response) {
      refresh(temp);

      const chatUrl = `/chat/${temp.id}`;
      setUserAsUnread(temp.id);
      history.push(chatUrl);

      // const SubmitPrompt = (user_msg, prompt) => {
      addNewMessage(temp.id, user_msg, prompt);
      // };
    } else {
      showToast("Something went wrong!");
    }
  };
  const moveToCategoryHandler = async (e, item) => {
    const payload = {
      promptId: contact._id,
      item:item,
    };
    return await axios
      .put(BASE_URL + "/chat/shareprompt", payload)
      .then(async function (response) {
        console.log(response);
        handleRefreshData();
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  return (
    <>
      <div className="prompt_box_sidebar"
        onClick={(event) => handleSubmit(event, contact.title, contact.prompt_value)} >
        <div className="prompt_text">
          {contact.title}
        </div>  
        <div className="sidebar-contact__bottom-content">
          <div className="sidebar-contact__time">
            <Dropdown
              showDropdown={openDropdownIndex === index}
              toggleDropdown={(e) => toggleDropdown(e, index)}
              onDeleteClick={(e) => askToDelete(e, contact)}
              onShareClick={(e) => askToShare(e, contact)}
              promptsData={promptsData}
              moveToCategoryHandler={moveToCategoryHandler}
            // (e) => handleDeleteClick(e, contact.id)
            />
            <span
              style={{ padding: 0 }}
              className="deleteChatBtn"
              onClick={(e) => removeFromFav(e, contact)}
            >
              <Icon id={'filledStar'} />
            </span>
          </div>
        </div>
        {/* <Icon id="send" className="chat__input-icon prompt_icon" /> */}
      </div>

      <DeleteChatModal
        isModalOpen={askToDeleteModal}
        contact={selectedContact}
        yesDelete={handleDeleteClick}
        justCloseIt={justCloseIt}
      />
      <ShareChatModal
        isModalOpen={askToShareModal}
        contact={selectedContact}
        justCloseIt={justCloseIt}
      />
    </>
  );
};



const Dropdown = ({ promptsData,showDropdown, toggleDropdown, onDeleteClick, onShareClick,moveToCategoryHandler }) => {
  
  const [showSubmenu, setShowSubmenu] = useState(false);
  const [subcategories, setsubcategories] = useState([]);
  const dropdownRef = useRef(null);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const toggleSubmenu = (e) => {
      e.stopPropagation();
      e.preventDefault();
      setShowSubmenu(true);
    Setsubcatopen(false)

  };

  const handleMenu = (e) => {
      e.stopPropagation();
      e.preventDefault();
      const { clientX, clientY } = e;
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;
      const dropdownWidth = 200; // Approximate width of the dropdown menu
      const dropdownHeight = 150; // Approximate height of the dropdown menu

      let adjustedLeft = clientX;
      let adjustedTop = clientY;

      // Adjust horizontal position if the dropdown would overflow the viewport
      if (clientX + dropdownWidth > viewportWidth) {
          adjustedLeft = viewportWidth - dropdownWidth - 10; // 10px margin from the edge
      }

      // Adjust vertical position if the dropdown would overflow the viewport
      if (clientY + dropdownHeight > viewportHeight) {
          adjustedTop = viewportHeight - dropdownHeight - 10; // 10px margin from the edge
      }

      setDropdownPosition({ top: adjustedTop, left: adjustedLeft });
      toggleDropdown(e);
      setShowSubmenu(false);
    Setsubcatopen(false)

  };

  const handleClickOutside = (event) => {
      // if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      //     toggleDropdown(event);
      // }
  };

  // useEffect(() => {
  //     if (showDropdown) {
  //         document.addEventListener("mousedown", handleClickOutside);
  //     } else {
  //         document.removeEventListener("mousedown", handleClickOutside);
  //     }

  //     return () => {
  //         document.removeEventListener("mousedown", handleClickOutside);
  //     };
  // }, [showDropdown]);
  const [subcatopen,Setsubcatopen]=useState(false)
  const getsubcateogories=(e,id,currentname)=>{
    e.stopPropagation();
      e.preventDefault();
      const matches = [];

        for (let category of promptsData.prompt_category) {
          for (let subCategory of promptsData.prompt_sub_category) {
            if (subCategory.category_id===id && category.name===currentname) {
              matches.push({
                category: category.name,
                subCategory: subCategory.name,
                category_id:category._id,
                subCategory_id:subCategory._id,
              });
            }
          }
        }
        console.log(matches)
setsubcategories(matches)
      Setsubcatopen(true)
    // alert("Ff")
  }
  return (
    <span style={{ height: "100px", overflowY: "scroll" }} className="userChatIcon truncateUserName" Style={{ padding: 0 }} onClick={(e) => handleMenu(e)}>
    <Icon id="cogSm" />
    {showDropdown && (
        <div ref={dropdownRef} className="settingMenu"
            style={{
                position: "fixed",
                top: dropdownPosition.top,
                left: dropdownPosition.left,
                zIndex: 1000,
                backgroundColor: "white",
                boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                borderRadius: "4px",
            }}>
            
            <div onClick={onDeleteClick}>Delete</div>
            <div onClick={onShareClick}>Share</div>
        
            
                    <div onClick={toggleSubmenu}>{`Move >`}</div>
                       {showSubmenu && (
                        <div className="submenu">
                          {promptsData.prompt_category.map((item)=>(
                            <>
                              {/* {item.type==="agency" &&<div onClick={(e)=>moveToCategoryHandler(e,item._id)}>{item.name}</div>} */}
                              {item.type==="agency" && 
                              <div onClick={(e)=>getsubcateogories(e,item._id,item.name)}>{item.name}</div>
                              }
                            </>
                            ))} 
                           </div>
                        )}
                      
                                {subcatopen &&showSubmenu && 
                                    <div className="submenu-1">
                                      {subcategories.map((item)=>(
                                      <div onClick={(e)=>moveToCategoryHandler(e,item)}>{item.subCategory}</div>
                                      ))}
                                    </div>
                                  }  
           </div>
          )}
</span>
  );
};

export default FavPrompt;
