// import Item from "antd/lib/list/Item";
// import { useAgencyInfo } from "context/agency";
// import { useAppServices } from "hook/services";
// import React from "react";
// import { useState } from "react";
import crypto from "crypto-js";
import {
  useState
} from "react";
// import 'crypto-js/addon/pad-iso10126';
// import 'crypto-js/enc-utf8';

const SsoHandler = () => {
  const [ssodata, setssodata] = useState("");
  // const SSO = {
  //   navbutton: {
  //     app_id: "65a7bf4f5f3d2f082f7d330e",
  //     key: "cf965064-a325-4590-bc9a-7b7df3647530",
  //   },
  //   knowledgebase: {
  //     app_id: "65a7bf3a6a9bd09ff9e4e8d2",
  //     key: "3d47bfb0-a21c-4831-950b-c71e496324e1",
  //   },
  //   newsfeed: {
  //     app_id: "65a7c942b6742ec03e79f82f",
  //     key: "27339a9a-2dad-403d-bf14-e6fa150f8bae",
  //   },
  //   dynamicurl: {
  //     app_id: "65a9402a8b06251c99a21f7a",
  //     key: "f3434ae3-089a-46fd-9222-561220f3091b",
  //   },
  // };
  // const decript_data = (payload, app) => {
  //   console.log(payload, app, "payloadpayloadpayloadpayload");
  //   const ciphertext = crypto.AES.decrypt(payload, app.key).toString(
  //     crypto.enc.Utf8
  //   );
  //   setssodata(JSON.parse(ciphertext));
  //   console.log(JSON.parse(ciphertext), "decript_data");
  // };
  const checkSSO = (app) => {
    const searchParams = new URLSearchParams(document.location.search);
    const activepayload = searchParams.get("activepayload");
    console.log(JSON.parse(activepayload), 'JSON.parse(activepayload)JSON.parse(activepayload)JSON.parse(activepayload)JSON.parse(activepayload)')
    setssodata(JSON.parse(activepayload));
    // decript_data(
    //   "U2FsdGVkX1+TdOzjY3G0r7aIIWFqSoG/tqEBAKuvUpNgUMrz3dBJCQnSyWo7HlNJ5K7iK6RE9wFNm+uEmYA9AxDS6H90wNKVPPDaLIsuT+L6R9hrK9oaBcuBR7SM22rSQhhEEdYWcxdV/BTSfuxqHnWcHKYqW2mxN20NQSG1oW52VWxeA1WAAswicGPA05a1OmWUtVVXDCUwzATWvSGjHG9FAgEz4jQMaunbiwp1RKts+F+b73tMlO4vazYO8sUAgngjj3LybvWqWsN13KfIWmScykwPqtStt9c1VcNwH6U=",
    //   { key: "953fc789-f043-429e-bc8a-eafa5bf1385e" }
    // );

    // const key = new Promise((resolve) => {
    //   window.parent.postMessage({ message: "REQUEST_USER_DATA" }, "*");
    //   console.log(
    //     window.parent.postMessage({ message: "REQUEST_USER_DATA" }, "*"),
    //     "test data"
    //   );
    //   const temp = window.addEventListener("message", ({ data }) => {
    //     if (data.message === "REQUEST_USER_DATA_RESPONSE") {
    //       decript_data(data.payload, {
    //         key: "953fc789-f043-429e-bc8a-eafa5bf1385e",
    //       });
    //     } else {
    //     }
    //     // console.log(temp, 'temptemptemptemptemptemp')
    //   });
    // });
    // return SSO[app];
  };
  return {
    SSO: ssodata,
    checkSSO: checkSSO,
  };
};
export default SsoHandler;