import React, { useContext, useState } from "react";
import "./styles/main.css";
import { useUsersContext } from "context/usersContext";
import ChatModal from "./components/ChatModal";
import { useLocation } from "react-router-dom";
import Prompts from "pages/Chat/components/Prompts";
import ChatAccordions from "pages/Chat/components/ChatAccordions";
import AddPromptModal from "components/AddPromptModal";
import { PromptsContext } from "context/promptsContext";
import PromptAccordions from "pages/Chat/components/PromptAccordions";
import Data from "data/contacts";
import FavChatAccordions from "pages/Chat/components/FavChatAccordions";
import SharedChatAccordions from "pages/Chat/components/SharedChatAccordions";
import FavPromptAccordions from "pages/Chat/components/FavPromptAccordions";
import AddChatCategoryModal from "components/AddChatCategoryModal";
import SharedPromptAccordions from "pages/Chat/components/SharedPromptAccordions";

const SettingsSidebar = ({ setIsSettingsPage }) => {
  const { users: contacts, locValid, isActiveSide, handleDeleteContact, processing, showExploreContent, enableExplore, enableChat, SSO } = useUsersContext();

  const handleConnect = (e) => {
    e.preventDefault();
    window.location.href = `https://connect.maybetech.com/?profile_id=${SSO?.id}&profile_name=${SSO?.email}&username=${SSO?.first_name}&type=chat`;
  };

  return (
    <>
      {locValid ? (
        <aside className={`sidebar ${!isActiveSide ? 'dNone' : ''}`}>
          <header className="header">
            <ChatModal processing={processing} enableExplore={enableExplore} enableChat={enableChat} showExploreContent={showExploreContent} setIsSettingsPage={setIsSettingsPage} />
          </header>
          <div className="sidebar__explore-content">
            <div className="sidebarWrapper">
              <div className="prompt_box_sidebar" >
                <div className="prompt_text" style={{ fontWeight: 'bold' }}>
                  Users
                </div>
              </div>
              <div className="prompt_box_sidebar" onClick={(e) => handleConnect(e)}>
                <div className="prompt_text" style={{ fontWeight: 'bold' }}>
                  Connect Facebook
                </div>
              </div>
            </div>
          </div>
        </aside>
      ) : (
        <></>
      )}
    </>
  );
};

export default SettingsSidebar;
