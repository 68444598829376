import React, { useEffect, useState } from "react";
import "./styles/main.css";
import maybe from "assets/images/maybe.png";
import axios from "axios";
import { useUsersContext } from "context/usersContext";
import Data from "data/contacts";
import { useHistory, useParams } from "react-router-dom";

const Home = () => {
  const { users, setUserAsUnread, addNewMessage, refresh, showToast, SSO } = useUsersContext();
  const history = useHistory();

  const [refreshAgain, setRefreshAgain] = useState()
  const { ChatSubmit } = Data(SSO);
  const darkTheme = document.body.classList.contains("dark-theme");
  const { users: contacts, locValid, setlocValid } = useUsersContext();
  const pathname = window.location.pathname;
  const loc_id = pathname.split("/")[1];
  const loc_name = decodeURI(pathname.split("/")[2]);
  const username = decodeURI(pathname.split("/")[3]);
  const handleSubmit = async (e) => {
    e.preventDefault();

    var payload = {
      facebook: [e.target.facebook.value],
      instagram: [e.target.instagram.value],
      profile_id: "v2/" + SSO?.id,
      profile_name: SSO?.email,
    };
    const response = await axios
      .post(
        "https://3yv7611xa1.execute-api.eu-west-2.amazonaws.com/Prod/haumea/api/profiles",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
      });
  };

  const handleChatSubmit = async (e) => {
    e.preventDefault();
    const name = e.target.name.value;
    const payload = {
      // name: name,
      locationId: SSO?.id,
      username: SSO?.first_name,
    };

    const temp = await ChatSubmit(payload);

    if (!temp?.response) {
      refresh(temp);

      const chatUrl = `/chat/${temp.id}`;
      setUserAsUnread(temp.id);
      history.push(chatUrl);
    } else {
      showToast('Something went wrong!');
    }
  };

  useEffect(() => {
    console.log('activepayloadactivepayloadactivepayload', SSO)
  },[])

  return (
    <div className="home">
      {locValid ? (
        <>
          <div className="home__img-wrapper">
            <h1 className="home__title">
              {" "}
              Do your best work faster with personalised AI
            </h1>
            <button
              className="btn btn-chat btn-sm"
              aria-label="New chat"
              style={{
                marginTop: "28px",
                width: "100%",
                padding: "20px",
                fontSize: "20px",
              }}
              onClick={(e) => handleChatSubmit(e)}
            >
              Start New Chat
            </button>
          </div>
        </>
      ) : (
        <>
          <h3>
            To use Maybe* AI please connect us with your social media accounts.
          </h3>
          <button className="form-btn" onClick={() => handleChatSubmit()}>
            {" "}
            Continue with Facebook{" "}
          </button>
        </>
      )}
    </div>
  );
};

export default Home;
