import React, { useEffect, useRef, useState } from "react";
import Icon from "components/Icon";
import { Link } from "react-router-dom";
import { useUsersContext } from "context/usersContext";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import DeleteChatModal from "components/DeleteChatModal";
import ShareChatModal from "components/ShareChatModal";
import axios from "axios";
import env from "config";
const BASE_URL = `${env.API_URL}/v1`;

const FavContact = ({ addChatCategoryHandle,chatCategories,contact, onDelete, index, openDropdownIndex, setOpenDropdownIndex, favourites, getFavourites }) => {
  const { setUserAsUnread, showToast, setUsers, SSO,handleRefreshData } = useUsersContext();
  const history = useHistory();
  // alert(SSO?.id)
console.log(getFavourites,"pppppppppppp")
  const [askToDeleteModal, setAskToDeleteModal] = useState(false);
  const [askToShareModal, setAskToShareModal] = useState(false);
  const [selectedContact, setSelectedContact] = useState();

  const isOpen = openDropdownIndex === index;
  const toggleDropdown = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setOpenDropdownIndex(isOpen ? null : index);
  };

  const askToDelete = async (e, contact) => {
    e.stopPropagation();
    e.preventDefault();
    setSelectedContact(contact);
    setAskToDeleteModal(true);
  }
  const askToShare = async (e, contact) => {
    e.stopPropagation();
    e.preventDefault();
    setSelectedContact(contact);
    setAskToShareModal(true);
  }

  // let parameter = useParams();
  const getLastMessage = () => {
    const messageDates = Object.keys(contact.messages);
    const recentMessageDate = messageDates[messageDates.length - 1];
    const messages = [...contact.messages[recentMessageDate]];
    const lastMessage = messages.pop();
    return lastMessage;
  };

  const handleDeleteClick = async () => {
    const deleteRes = await onDelete(selectedContact?.id);
    if (deleteRes.status === 200) {
      setUsers((users) => users.filter((user) => user.id !== selectedContact?.id));
      const redirectUrl = `/`;
      history.push(redirectUrl);
    } else {
      showToast('Something went wrong!')
    }
  };

  const handleFavourite = async (e, contact) => {
    e.stopPropagation();
    e.preventDefault();
    const response = await axios.get(`${BASE_URL}/chat/add/favourite?chat_id=${contact?.id}&user_id=${SSO?.userId}&location_id=${SSO?.id}`);
    // await axios.get(`${BASE_URL}/remove/favourite?id=${contact?.id}`);
    console.log(contact, response)
  }

  const removeFromFav = async (e, contact) => {
    e.stopPropagation();
    e.preventDefault();
    const favoriteId = favourites.find(favorite => favorite.chat_id === contact?.id)?._id;
    const response = await axios.get(`${BASE_URL}/chat/remove/favourite?chatId=${favoriteId}&user_id=${SSO?.userId}&location_id=${SSO?.id}`);
    if (response) {
      getFavourites();
    }
  }

  const justCloseIt = () => {
    setAskToDeleteModal(false);
    setOpenDropdownIndex(false);
    setAskToShareModal(false);
  }

  const lastMessage = getLastMessage(contact);

  const truncateString = (str, numChars) => {
    if (str?.length > numChars) {
      return `${str?.slice(0, numChars)} ...`;
    }

    return str;
  };

  const truncatedMessage = truncateString(lastMessage?.content, 35);
  const truncatedName = truncateString(contact.name, 35);
  const moveToCategoryHandler = async (e, categoryId) => {
    const payload = {
      chatId: contact.id,
      category: categoryId,
    };
    console.log(payload);
    return await axios
      .put(BASE_URL + "/chat/sharechat", payload)
      .then(async function (response) {
        console.log(response);
        handleRefreshData();
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  return (
    <>
      <Link
        className="sidebar-contact"
        to={`/chat/${contact.id}`}
        onClick={() => setUserAsUnread(contact.id)}
      >
        <div className="sidebar-contact__content">
          <div className="sidebar-contact__top-content">
            <h2 className="sidebar-contact__name"> {truncatedName} </h2>
            <p className="sidebar-contact__message-wrapper">
              {lastMessage?.status && (
                <Icon
                  id={
                    lastMessage?.status === "sent" ? "singleTick" : "doubleTick"
                  }
                  aria-label={lastMessage?.status}
                  className={`sidebar-contact__message-icon ${lastMessage?.status === "read"
                    ? "sidebar-contact__message-icon--blue"
                    : ""
                    }`}
                />
              )}
              <span
                className={`sidebar-contact__message ${!!contact.unread ? "sidebar-contact__message--unread" : ""
                  }`}
              >
                {/* <div dangerouslySetInnerHTML={{ __html: lastMessage?.content }} /> */}
                {contact.typing ? (
                  <i> typing...</i>
                ) : (
                  <div
                    className="limited-lines"
                    dangerouslySetInnerHTML={{ __html: truncatedMessage }}
                  />
                )}
              </span>
            </p>
          </div>
          <div className="sidebar-contact__bottom-content">
            <div className="sidebar-contact__time">
              <Dropdown
                showDropdown={isOpen}
                toggleDropdown={toggleDropdown}
                onDeleteClick={(e) => askToDelete(e, contact)}
                onShareClick={(e) => askToShare(e, contact)}
                chatCategories={chatCategories}
                moveToCategoryHandler={moveToCategoryHandler}
                addChatCategoryHandle={addChatCategoryHandle} 
              // (e) => handleDeleteClick(e, contact.id)
              />
              <span
                className="deleteChatBtn"
                onClick={(e) => removeFromFav(e, contact)}
              >
                <Icon id="filledStar" />
              </span>
            </div>
          </div>
        </div>
      </Link>

      <DeleteChatModal
        isModalOpen={askToDeleteModal}
        contact={selectedContact}
        yesDelete={handleDeleteClick}
        justCloseIt={justCloseIt}
      />
      <ShareChatModal
        isModalOpen={askToShareModal}
        contact={selectedContact}
        justCloseIt={justCloseIt}
      />
    </>
  );
};

const Dropdown = ({ addChatCategoryHandle, chatCategories,showDropdown, toggleDropdown, onDeleteClick, onShareClick,moveToCategoryHandler }) => {
  const [showSubmenu, setShowSubmenu] = useState(false);
  const dropdownRef = useRef(null);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });

  const toggleSubmenu = (e) => {
      e.stopPropagation();
      e.preventDefault();
      setShowSubmenu(!showSubmenu);
  };

  const handleMenu = (e) => {
      e.stopPropagation();
      e.preventDefault();
      const { clientX, clientY } = e;
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;
      const dropdownWidth = 200; // Approximate width of the dropdown menu
      const dropdownHeight = 150; // Approximate height of the dropdown menu

      let adjustedLeft = clientX;
      let adjustedTop = clientY;

      // Adjust horizontal position if the dropdown would overflow the viewport
      if (clientX + dropdownWidth > viewportWidth) {
          adjustedLeft = viewportWidth - dropdownWidth - 10; // 10px margin from the edge
      }

      // Adjust vertical position if the dropdown would overflow the viewport
      if (clientY + dropdownHeight > viewportHeight) {
          adjustedTop = viewportHeight - dropdownHeight - 10; // 10px margin from the edge
      }

      setDropdownPosition({ top: adjustedTop, left: adjustedLeft });
      toggleDropdown(e);
      setShowSubmenu(false);
  };

  const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          toggleDropdown(event);
      }
  };

  useEffect(() => {
      if (showDropdown) {
          document.addEventListener("mousedown", handleClickOutside);
      } else {
          document.removeEventListener("mousedown", handleClickOutside);
      }

      return () => {
          document.removeEventListener("mousedown", handleClickOutside);
      };
  }, [showDropdown]);
  
  return (
    <span style={{ height: "100px", overflowY: "scroll" }} className="userChatIcon truncateUserName" Style={{ padding: 0 }} onClick={(e) => handleMenu(e)}>
    <Icon id="cogSm" />
    {showDropdown && (
        <div ref={dropdownRef} className="settingMenu"
            style={{
                position: "fixed",
                top: dropdownPosition.top,
                left: dropdownPosition.left,
                zIndex: 1000,
                backgroundColor: "white",
                boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                borderRadius: "4px",
            }}>
            
            <div onClick={onShareClick}>Share</div>
            <div onClick={onDeleteClick}>Delete</div>
            
                    <div onClick={toggleSubmenu}>{`Move >`}</div>
                    {showSubmenu && (
                        <div className="submenu">
                            {/* Add your submenu items here */}
                          {chatCategories.map((item)=>(
                            <>
                            {item.name!=="Uncategorised" &&<div onClick={(e)=>moveToCategoryHandler(e,item._id)}>{item.name}</div>}
                            </>
                          ))}
                          <div onClick={addChatCategoryHandle}> + Add New</div>
                        </div>
                    )}
                
        </div>
    )}
</span>
  );
};

export default FavContact;
