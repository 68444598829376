import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import env from "config";

const Data = (activeLocation) => {
  const BASE_URL = `${env.API_URL}/v1`;
  const [data, setData] = useState([]);
  const [processing, setProcessing] = useState(true);

  const GetChats = async () => {
    // setProcessing(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/chat/filter?locationId=${activeLocation?.id}&userId=${activeLocation?.userId}`
      );
      setData(response.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      setProcessing(false);
    }
  };

  const ChatSubmit = async (payload, fromPrompt = false) => {
    
    return await axios
      .post(BASE_URL + "/chat", {
        // userId: { id: SSO?.userId, type: "author", username: SSO?.first_name },
        userId: { id: activeLocation?.userId, type: "author", username: activeLocation?.first_name },
        ...payload,
      })
      .then(async function (response) {
        var chat_data = {};
        chat_data["Welcome " + response.data.data.username] = [];
        const chat = {
          id: response.data.data.conversation_id,
          locationId: response.data.data.locationId,
          userId: response.data.data.userId,
          name: "",
          whatsapp_name: response.data.data.username,
          messages: chat_data,
          welcome_array: "Welcome " + response.data.data.username,
          fromPrompt: fromPrompt,
        };
        console.log(chat, "chatchatchatchatchatchatchat");
        return chat;
      })
      .catch(function (error) {
        return error;
      });
  };

  useEffect(async () => {
    console.log(activeLocation, "activeLocationactiveLocationactiveLocation");
    if (activeLocation?.id != "" && activeLocation?.id != undefined) {
      GetChats();
    }
  }, [activeLocation]);

  return {
    data,
    processing,
    handleRefreshData: GetChats,
    ChatSubmit,
    GetChats,
  };
};

export default Data;
